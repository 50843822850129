header {
  background-color: var(--background-color);
  height: 8.5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  width: 100%;
  position: relative;
}

header .headerContent {
  position: absolute;
  height: 100%;
  justify-content: center;
  /* margin-top: 1%; */
  margin-bottom: 1%;
  /* margin: 0 auto; */
  width: 90%;
}

header .left {
  /* display: flex; */
  display: flex;
  width: 80%;
  height: 100%;
  position: absolute;
  justify-content: center;
  margin-left: 10%;
}

header .right {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 490px){
  /* .imgHeader {
    width: 80%;
  } */

  header {
    height: 22vh;
  }

  header .headerContent {
    position: absolute;
    top: 0;
  }

  .imgHeader {
    position: absolute;
    width: 246px;
    height: 106px;
    margin-left: 20%;
    margin-right: 20%;
  }
}

/* @media only screen and (min-width: 550px){

  .imgHeader {
    width: 40%;
    height: 60%;
    margin-left: 30%;
    margin-right: 30%;
  }
}

@media only screen and (min-width: 860px){
  .imgHeader {
    width: 30%;
    height: 60%;
    margin-left: 35%;
    margin-right: 35%;
  }
}

@media only screen and (min-width: 1100px){
  .imgHeader {
    width: 25%;
    height: 60%;
    margin-left: 37.5%;
    margin-right: 37.5%;
  }
} */

/* @media only screen and (max-width: 500px) {
  .imgHeader {
    width: 100%;
    height: 100%;
  }
} */