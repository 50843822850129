@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

/* COMMON CONFIGURATIONS*/

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #0d0d0e;
}

.lds-ring {
  margin: 0 auto;
}

header {
  display: flex;
  justify-content: center;
  background-color: var(--light);
}

.hourReservationMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--light);
  color: var(--dark);
  text-align: center;
  width: 100%;
}

section {
  border-radius: 25px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.containerTitleReservation {
  font-size: 31px;
  color: var(--background-color);
  display: flex;
  align-items: center;
  font-weight: 600;
  width: 80%;
  justify-content: center;
  flex: 1;
  margin-top: 12%;
  width: 100% !important;
}

.rutInputContainer {
  display: flex;
  justify-content: center;
}

input {
  width: 96%;
}

.clientDataContainerH button[type="submit"] {
  width: 60%;
  background-color: #0174b5;
  color: var(--light);
}

button[type="submit"]:hover {
  background-color: var(--hover-color);
}

.clientDataContainerH button[type="submit"]:disabled {
  background-color: #0174b5;
}
/* button[type="submit"]:disabled {
  background-color: var(--disabled-color);
  color: var(--disabled-light);
} */

input .fieldInput :read-only {
  background-color: var(--not-editable-color);
}

label {
  display: block;
}

form {
  width: 100%;
}

.dataField {
  display: flex;
  align-items: center;
  font-size: large;
}

.react-datepicker-wrapper {
  margin-bottom: 3%;
}

.react-datepicker-wrapper input {
  width: 97.4%;
  padding-right: 0px;
}

.dataField button {
  width: 25%;
  background-color: var(--principal-color);
  color: var(--light);
}

.dataField button:hover {
  background-color: var(--hover-color);
}

.formInput {
  -webkit-appearance: none;
  margin-bottom: 2%;
  appearance: none;
  background-color: var(--gray-100);
  border-radius: 6px;
  color: var(--gray-800);
  font-size: 1rem;
  width: 100%;
  background-color: white;
}

.formInput::placeholder {
  color: #80808060;
  font-weight: lighter;
  font-size: 14px;
}

.formInput:focus {
  outline: none;
}

.clientInputDataContainer {
  width: 88%;
  display: flex;
  border-radius: 5px;
}

.clientDataContainerH {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.popUpContainer {
  /* height: 45vh; */
  padding-bottom: 5%;
  width: 100%;
  background-color: var(--light);
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: ; */
}

.popUpTitle {
  font-size: 31px;
  color: var(--background-color);
  align-items: center;
  /* font-weight: 900; */
  width: 40%;
  justify-content: start;
  font-weight: 500;
  /* font-family: 'Open Sans', sans-serif; */
}

.popUpSubtitle {
  color: #3A3A3A;
  display: flex;
  /* align-items: center; */
  font-weight: 400;
  text-align: center;
  /* flex: 1 1; */
  margin-bottom: 4%;
  width: 90%;
  justify-content: center;
  font-size: 18px;
  text-align: justify;
  margin-top: 5%;
}

/* DESKTOP VIEW CONFIGURATIONS*/

/* @media only screen and (min-width: 500px) {

  .img-header {
    width: 20%;
    height: auto;
  }

  .rutContainer {
    padding: 15px;
    width: 800px;
    margin: 0 auto;
  }

  /* .clientDataContainerH {
    display: flex;
    padding: 15px;
    width: 90%;
    margin: 0 auto;
    flex-direction: column;
    order: 2;
    align-items: center;
  }

  .inputPair .fieldInput {
    width: 96%;
  }

  form {
    padding: 10px;
  }

  select {
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    background-color: var(--gray-100);
    color: var(--gray-800);
    border-radius: 10px;
    margin: 5px;
  }

  select:disabled {
    background-color: var(--not-editable-color);
  }
} */

/* SMARTPHONE VIEW CONFIGURATIONS*/

@media only screen and (min-width: 490px) {

  .img-header {
    width: 60%;
    height: auto;
  }

  .rutContainer {
    padding: 10px;
    width: 80%;
    margin: 0 auto;
  }

  .clientInputDataContainer {
    width: 84%;
    display: flex;
    border-radius: 5px;
  }

  form {
    padding: 5px;
  }

  .clientDataContainerH {
    width: 420px;
    background-color: var(--light);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    -moz-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    align-items: center;
    flex: none;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 4%;
    padding-right: 4%;
    position: relative;
    margin-top: -9.5vh;
    height: auto;
    margin-bottom: 5%;
  }

  .inputPair .fieldInput {
    width: 90%;
  }

  .reservationButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97%;
    cursor: pointer;
  }

  .reservationButton:disabled {
    background-color: #0174b590 !important;
    color: white !important;
    cursor: not-allowed;
  }


  select {
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    background-color: var(--gray-100);
    color: var(--gray-800);
    border-radius: 10px;
    margin: 5px;
  }

  .dataField button {
    font-size: small;
  }
}

@media only screen and (min-width: 500px) {
  .clientDataContainerH {
    width: 420px;
    padding-top: 1%;
    padding-bottom: 2%;
  }
}

@media only screen and (min-width: 700px) {
  .clientDataContainerH {
    width: 420px;
    padding-top: 1%;
    padding-bottom: 2%;
  }
}

/* @media only screen and (min-width: 800px) {
  .clientDataContainerH{
    width: 70%;
    padding-top: 6%;
    padding-bottom: 6%;
  }
}

@media only screen and (min-width: 1000px) {
  .clientDataContainerH{
    width: 50%;
    /* padding-top: 4%;
    /* padding-bottom: 4%;
  }
} */