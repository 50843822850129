.hourFormConfirmation{
  width: 100%;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hourFormConfirmation .containerTitle{
  font-size: 2em;
  font-weight: bold;
  margin-top: 2%;
  color: var(--principal-color);
  text-align: center;
}

.formHour{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px
}

.hourInfoConfirmation{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 450px;
  background-color: white;
  flex-direction: column;
  margin-top: 4%;
  box-shadow: 0px 1px 5px 0px #787878;
  margin-bottom: 100px;
  z-index: 9;
}

.hourInfoAnnulment{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 450px;
  background-color: white;
  flex-direction: column;
  margin-top: 4%;
  box-shadow: 0px 1px 10px 0px #787878;
  margin-bottom: 100px;
  z-index: 9;
  margin-top: -9.5vh;
}

.hourInfoConfirmation .title{
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 2%;
  color: var(--background-color);
  margin-bottom: 2%;
}

.hourInfoAnnulment .title {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 2%;
  color: var(--background-color);
  margin-bottom: 2%;
}

.dataContainerConfirmation{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.infoContainer{
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  width: 100%;
  background-color: var(--background-color);
  margin-bottom: 3%;
  border-radius: 15px;
  padding: 2%;
}

.dateInfo{
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.buttonsConfirmation{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3%;
  font-weight: bold;
}

.extraButtons{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
}

.buttonsConfirmation button{
  width: 45%;
  height: 40px;
  color: white;
  border: none;
  border-radius: 15px;
  margin-top: 2%;
  font-weight: bold;
  cursor: pointer;
}

.buttonsConfirmation .buttonAnnulment{
  width: 93%;
  height: 40px;
  color: white;
  border: none;
  border-radius: 15px;
  margin-top: 2%;
  font-weight: bold;
}

@media (max-width: 500px) {
  .hourInfoConfirmation .title{
    font-size: 1.3em;
  }
  .hourFormConfirmation .containerTitle{
    font-size: 1.5em;
  }
  .formHour{
    width: 90%;
  }
  .hourInfoConfirmation{
    width: 80%;
  }
  .buttonsConfirmation button{
    font-size: small;
  }
}

@media (max-width: 490px) {
  .hourInfoConfirmation {
    /* width: 100%; */
    /* background-color: transparent; */
    box-shadow: none;
  }

  .hourInfoAnnulment{
    margin-top: 2%;
    width: 100%;
    box-shadow: none;
    background-color: transparent;
  }
}