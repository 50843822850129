.contentConfirmation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 15px;
    /* border-radius: 10px; */
    align-items: center;
    /* margin-top: 2%; */
    flex: 1;
    width: 100%;
}

.dataContainer {
    margin: 0 auto;
    margin-top: 15px;
    border-radius: 10px;
    width: 87%;
}

.subTitleContainer {
    color: var(--background-color);
    font-size: 24px;
    text-align: center;
}

.reservationTitle {
    display: flex;
    flex-direction: column;
    /* margin: 15px auto; */
    width: 100%;
    align-items: center;
    font-size: 20px;
    margin-bottom: 2%;

}

body {
  overflow: auto !important;
}

.rowInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 10%;
    height: auto;
    background-color: transparent;
    border-radius: 15px;
    font-size: calc(13px + .35vw);
    position: relative;
    margin-bottom: 1%;
    margin-top: 1%;
}

.rowInfo div .dataLabelTitle{
    color: var(--background-color);

}
.rowInfo div {
    color: #3A3A3A;
}

.subTitle {
    color: var(--principal-dark-blue);
    text-align: center;
}

.dateContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 70%;
    height: 7vh;
    align-items: center;
    background-color: var(--principal-blue);
    border-radius: 10px;
    color: var(--light);
}

.reservationData {
    width: 100%;
    margin: 10px auto;
}

.patientData {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 10px auto;
    width: 75%;
}


.title {
    color: white;
    font-size: 24px;
    flex: 4;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 3%;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-top: 4%;
}

.reservationTitle .title {
    color: var(--background-color);
    font-size: 31px;
    font-weight: bolder;
}

.titleBody {
    display: flex;
    justify-content: center;
    height: 12vh;
    flex-direction: column;
    margin-top: 6%;
    margin-bottom: 4%;
    background-color: var(--background-color);
    border-radius: 10px;
    width: 80% !important;
}

.clientData {
    width: 90%;
    background-color: var(--hover-color);
    margin: 5px auto;
    padding: 5px;
    border-radius: 5px;
    color: var(--white);
}

.hourData {
    width: 90%;
    background-color: var(--principal-blue);
    margin: 5px auto;
    padding: 5px;
    border-radius: 5px;
    color: var(--white);
}

.buttons {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.containerTopButton {
    width: 100% !important;
}

.containerTopButton:hover {
    background-color: var(--background-color) !important;
    color: white !important;
}

.containerBottomButton {
    width: 48% !important;
    background-color: white !important;
    color: var(--background-color) !important;
    border: 1px solid var(--background-color);
    margin-top: 4% !important;
}

.containerBottomButton:hover {
    background-color: var(--background-color) !important;
    color: white !important;
}


@media only screen and (min-width: 490px) {
  .contentConfirmation {
    /* width: 72%; */
    background-color: var(--light);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    -moz-box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    box-shadow: 0px 1px 10px 0px rgba(120, 120, 120, 1);
    align-items: center;
    flex: none;
    padding-top: 2%;
    padding-bottom: 5%;
    padding-left: 3%;
    padding-right: 3%;
    position: relative;
    margin-top: -9.5vh;
    height: auto;
    margin-bottom: 5%;
  }
}


@media only screen and (min-width: 490px) {
  .contentConfirmation{
    width: 380px;
    padding-top: 2%;
    padding-bottom: 2%;
  }
}

@media only screen and (min-width: 670px) {
  .contentConfirmation{
    width: 440px;
    padding-top: 2%;
    padding-bottom: 2%;
  }
}

/* @media only screen and (min-width: 1070px) {
  .contentConfirmation{
    width: 40%;
    padding-top: 1%;
    padding-bottom: 2%;
  }

}

@media only screen and (min-width: 1200px) {
  .contentConfirmation{
    width: 35%;
    padding-top: 1%;
    padding-bottom: 2%;
  } */

/* } */

@media only screen and (max-width: 750px) {
  .reservationTitle .title {
    color: var(--background-color);
    font-size: 26px;
    font-weight: bolder;
}

}